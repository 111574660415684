import { CALCULATION_FORMULA } from 'constants/data-library';
import { formatNumber } from 'shared/helpers/Chart/chart-util';
import { DashboardType } from 'views/DataLibrary/reducers/types';

export const getTooltipContent = (item: string): string => {
	switch (item) {
		case 'influencers':
			return 'Total number of unique influencers in the campaign';
		case 'followers':
			return 'Total number of followers across all influencers';
		case 'platforms':
			return 'Number of social media platforms used in the campaign';
		case 'impressions':
			return 'Total number of times content was displayed';
		case 'grossReach':
			return 'Total reach across all content';
		case 'engagements':
			return CALCULATION_FORMULA.ENGAGEMENT_TOTAL;
		case 'actualEngagementRate':
			return CALCULATION_FORMULA.SUMMARY.ACTUAL_ENGAGEMENT_RATE;
		case 'assignments':
			return 'Total number of content pieces created';
		case 'clicks':
			return 'Total number of clicks on campaign content';
		case 'conversions':
			return 'Total number of conversions from the campaign';
		case 'costPerEngagement':
			return CALCULATION_FORMULA.CPE;
		case 'costPerReach':
			return 'Total cost divided by total reach';
		case 'viewThroughRate':
			return 'Percentage of users who watched the entire video content';
		case 'clickThroughRate':
			return CALCULATION_FORMULA.CTR;
		case 'invoicedAmount':
			return 'Total amount invoiced for the campaign';
		case 'reachRate':
			return CALCULATION_FORMULA.SUMMARY.REACH_RATE;
		default:
			return '';
	}
};

export const getIcon = (item: string): string => {
	switch (item) {
		case 'influencers':
			return 'dl-influencers';
		case 'followers':
			return 'audience';
		case 'platforms':
			return 'list';
		case 'impressions':
			return 'impressions';
		case 'grossReach':
			return 'gross-reach';
		case 'engagements':
			return 'engagement';
		case 'actualEngagementRate':
			return 'reach';
		case 'assignments':
			return 'dl-summary-content';
		case 'clicks':
			return 'click';
		case 'conversions':
			return 'conversions';
		case 'costPerEngagement':
			return 'cpe';
		case 'costPerReach':
			return 'cpr';
		case 'clickThroughRate':
			return 'click';
		case 'invoicedAmount':
			return 'file';
		case 'reachRate':
			return 'reach-rate';
		default:
			return '';
	}
};
export const getTitle = (item: string): string => {
	switch (item) {
		case 'influencers':
			return 'Influencers';
		case 'followers':
			return 'Total followers';
		case 'platforms':
			return 'Platforms';
		case 'impressions':
			return 'Impressions';
		case 'grossReach':
			return 'Gross reach';
		case 'engagements':
			return 'Engagement';
		case 'actualEngagementRate':
			return 'Engagement rate';
		case 'assignments':
			return 'Activations';
		case 'clicks':
			return 'Clicks';
		case 'conversions':
			return 'Conversions';
		case 'costPerEngagement':
			return 'CPE';
		case 'costPerReach':
			return 'CPR';
		case 'clickThroughRate':
			return 'CTR';
		case 'invoicedAmount':
			return 'Invoiced amount';
		case 'reachRate':
			return 'Reach rate';
		default:
			return '';
	}
};

export const shouldBeDisplayedInPercent = (title: string) => {
	switch (title) {
		case 'engagementRate':
			return true;
		case 'actualEngagementRate':
			return true;
		case 'reachRate':
			return true;
		case 'clickThroughRate':
			return true;
		case 'viewThroughRate':
			return true;
		default:
			return false;
	}
};

export const getPlatformShortName = (platform: string): string => {
	switch (platform.toLowerCase()) {
		case 'instagram':
			return 'IG';
		case 'tiktok':
			return 'TT';
		case 'youtube':
			return 'YT';
		default:
			return platform;
	}
};

export interface StatisticsSummary {
	influencers: number;
	followers: number;
	mediaObjects: number;
	assignments: number;
	platforms: string[];
	impressions: number;
	grossReach: number;
	estimatedUniqueReach: number;
	engagements: number;
	reachRate: number;
	engagementRate: number;
	actualEngagementRate: number;
	clicks: number;
	conversions: number;
	invoicedAmount: string;
	currency: string;
	costPerReach: number;
	viewThroughRate: number;
	clickThroughRate: number;
	costPerEngagement: number;
	uniqueInfluencers: number;
}

export type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];
export type Entry<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

export const updateInfluencersValue = (dashboard: DashboardType, data: Entries<StatisticsSummary>): Entries<StatisticsSummary> => {
	const summary = [...data];
	for (let i = 0; i < summary.length; i++) {
		if (summary[i][0] === 'influencers') {
			summary[i][1] = dashboard.uniqueInfluencers ?? summary[i][1];
			break;
		}
	}

	return summary;
};

export type SummaryItemKey = keyof StatisticsSummary;

export const getValueFromSummary = (key: SummaryItemKey, summaryData: Entries<StatisticsSummary>): string | number => {
	if (!summaryData) return '-';
	const item = summaryData.find(([k]) => k === key);
	if (!item) return '-';

	const value = item[1];
	if (key === 'platforms' && Array.isArray(value)) {
		return value?.length > 0 ? value.map((platform) => getPlatformShortName(platform)).join(', ') : '0';
	}
	if (Array.isArray(value)) {
		return value.length > 0 ? value.length : '-';
	}
	return formatNumber(value, true);
};

export const orderConfig = {
	order: [
		'influencers',
		'followers',
		'assignments',
		'grossReach',
		'reachRate',
		'engagements',
		'actualEngagementRate',
		'clicks',
		'clickThroughRate',
		'conversions',
		'costPerEngagement',
		'costPerReach',
		'invoicedAmount',
		'impressions',
		'platforms',
	] as const,
	enabled: [
		'grossReach',
		'reachRate',
		'engagements',
		'costPerEngagement',
		'viewThroughRate',
		'actualEngagementRate',
		'clicks',
		'clickThroughRate',
		'impressions',
	] as const,
};
