import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { SummaryQuery } from 'api-queries';
import { convertFilterToQueryBuilder } from 'components/DataLibrary/AllRequests/convertFilterToQueryBuilder';
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import MetricBar from 'components/MetricBar';
import Tooltip from 'components/Tooltip';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import DataLibraryManager from 'services/ApiManager/DataLibrary.manager';
import StatisticsManager from 'services/ApiManager/Statistics.manager';
import DashboardHelper from 'services/DataLibrary/DashboardHelper';
import toast from 'services/Toast';
import { formatNumber, formatPercent } from 'shared/helpers/Chart/chart-util';
import Grid from 'styles/grid/grid';
import colors from 'styles/theme/colors';
import { formatAmount } from 'utils/formatters';
import { useAppDispatch, useAppSelector } from 'views//DataLibrary/hooks';
import { setSummaryItemsOnDashboard } from 'views/DataLibrary/reducers/DashboardSlice';
import { setDashboards } from 'views/DataLibrary/reducers/DashboardsSlice';
import { DashboardFilters, DashboardType } from 'views/DataLibrary/reducers/types';

import Styled from './Summary.style';
import {
	Entries,
	Entry,
	getIcon,
	getTitle,
	getTooltipContent,
	getValueFromSummary,
	orderConfig,
	shouldBeDisplayedInPercent,
	StatisticsSummary,
	SummaryItemKey,
	updateInfluencersValue,
} from './helpers';

/**
 * This component displays the summaryIcons without the toggle in the dashboard preview.
 * @returns {JSX.Element}
 */

interface DashboardSummaryItem {
	key: SummaryItemKey;
	hidden: boolean;
}

const Summary = (props: { inDataLibrary?: boolean }): JSX.Element => {
	const [paidDataIncluded, setPaidDataIncluded] = useState<boolean>(false);
	const [displayRealValue, setDisplayRealValue] = useState<boolean>(false);
	const [selectedValue, setSelectedValue] = useState<number | null>(null);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	// Initialize with default enabled items
	const [enabledItems, setEnabledItems] = useState<Set<SummaryItemKey>>(new Set(orderConfig.enabled));

	const dashboardFilter: DashboardFilters = useAppSelector((state) => state.dashboardFilter);
	const dashboard: DashboardType = useAppSelector((state) => state.dashboard);

	const statisticsManager = useInjection<StatisticsManager>(StatisticsManager);
	// const dashboardManager = useInjection<DataLibraryManager>(DataLibraryManager);

	const filter = paidDataIncluded ? { ...dashboardFilter, paidMediaDashboardId: dashboard.id } : dashboardFilter;
	const qb = convertFilterToQueryBuilder<SummaryQuery>(filter);

	let summaryData: Entries<StatisticsSummary> | undefined;
	let isLoading = true;

	const sortSummaryData = (data: Entries<StatisticsSummary>): Entries<StatisticsSummary> => {
		return [...data].sort((a, b) => {
			const aIndex = orderConfig.order.indexOf(a[0] as SummaryItemKey);
			const bIndex = orderConfig.order.indexOf(b[0] as SummaryItemKey);
			return aIndex - bIndex;
		});
	};

	if (dashboard.id) {
		const { result, error } = statisticsManager.showSummary(qb);
		if (error) {
			console.error(error.message);
		}
		isLoading = !result;
		if (result) {
			const excludedItems = ['currency', 'mediaObjects', 'engagementRate', 'invoicedAmount'];
			const data = Object.entries(result.attributes) as Entries<StatisticsSummary>;
			let currency = null;
			let invoicedAmount = null;

			data?.forEach((summaryItem) => {
				if (summaryItem[0] === 'invoicedAmount') {
					invoicedAmount = summaryItem[1];
				} else if (summaryItem[0] === 'currency') {
					currency = summaryItem[1];
				}
			});

			const tmpData = data?.filter((summaryItem) => !excludedItems.includes(summaryItem[0]));
			if (invoicedAmount && currency) {
				tmpData.push(['invoicedAmount', formatAmount(invoicedAmount, currency)]);
			}

			summaryData = sortSummaryData(updateInfluencersValue(dashboard, tmpData));
		}
	}

	// Load summary items from dashboard metadata when dashboard changes
	useEffect(() => {
		if (dashboard?.metaData?.summaryItems) {
			const enabledKeys = new Set<SummaryItemKey>(dashboard.metaData.summaryItems?.filter((item) => !item.hidden).map((item) => item.key));

			if (!setsAreEqual(enabledKeys, enabledItems)) {
				setEnabledItems(enabledItems);
			}
		}
	}, [dashboard]);

	// Helper function to compare sets
	const setsAreEqual = (a: Set<SummaryItemKey>, b: Set<SummaryItemKey>): boolean => {
		if (a.size !== b.size) return false;
		for (const item of a) {
			if (!b.has(item)) return false;
		}
		return true;
	};

	// If no summary items in metadata, use default configuration
	const initializeDefaultSummaryItems = () => {
		if (dashboard?.id && !dashboard?.metaData?.summaryItems) {
			const defaultItems: DashboardSummaryItem[] = orderConfig.order.map((key) => ({
				key: key as SummaryItemKey,
				hidden: !orderConfig.enabled.includes(key),
			}));
			setEnabledItems(new Set(defaultItems.map((item) => item.key)));
			try {
				saveSummaryItems(defaultItems);
			} catch (error) {
				console.error('Failed to save default summary items:', error);
			}
		} else if (dashboard?.metaData?.summaryItems) {
			// Create a Set of enabled keys from the metadata
			const enabledKeys = new Set<SummaryItemKey>(dashboard.metaData.summaryItems.filter((item) => !item.hidden).map((item) => item.key));
			setEnabledItems(enabledKeys);
		}
	};

	// Initialize default summary items when dashboard is loaded
	useEffect(() => {
		if (dashboard?.id) {
			initializeDefaultSummaryItems();
		}
	}, [dashboard?.id]);

	useEffect(() => {
		if (typeof summaryData !== 'undefined') {
			summaryData = updateInfluencersValue(dashboard, summaryData);
		}
	}, [dashboard.uniqueInfluencers]);

	const displayValue = (item: Entry<StatisticsSummary>, i: number) => {
		const title = item[0];
		const value = item[1];

		if (Array.isArray(value)) {
			return value?.length > 0 ? value?.length : 0;
		}
		if (shouldBeDisplayedInPercent(title) && Number(value) > 0) {
			return formatPercent(value);
		}
		return displayRealValue && i === selectedValue ? value : formatNumber(value, true);
	};

	const handleItemToggle = (item: SummaryItemKey) => {
		const newEnabledItems = new Set(enabledItems);
		if (newEnabledItems.has(item)) {
			newEnabledItems.delete(item);
		} else {
			newEnabledItems.add(item);
		}
		setEnabledItems(newEnabledItems);
	};

	const metricBarItems = summaryData && [
		{
			label: 'Influencers',
			value: getValueFromSummary('influencers', summaryData),
			tooltipText: 'Number of influencers in this campaign',
		},
		{
			label: 'Activations',
			value: getValueFromSummary('assignments', summaryData),
			tooltipText: 'Number of assignments in this campaign',
		},
		{
			label: 'Total followers',
			value: getValueFromSummary('followers', summaryData),
			tooltipText: 'Total number of followers',
		},
		{
			label: 'Networks',
			value: getValueFromSummary('platforms', summaryData),
			tooltipText: 'Networks in campaign',
		},
	];

	const dispatch = useAppDispatch();
	const manager = useInjection<DataLibraryManager>(DataLibraryManager);
	const dashboardHelper = useInjection<DashboardHelper>(DashboardHelper);

	const saveSummaryItems = (items?: DashboardSummaryItem[]) => {
		const dashboardSummaryItems: DashboardSummaryItem[] = items
			? items
			: orderConfig.order.map((key) => ({
					key: key as SummaryItemKey,
					hidden: !enabledItems.has(key as SummaryItemKey),
				}));
		manager
			.update(dashboard.id, { metaData: { summaryItems: dashboardSummaryItems } })
			.then(() => {
				dispatch(setSummaryItemsOnDashboard(dashboardSummaryItems));

				dashboardHelper.getDashboards().then((updatedDashboards) => {
					dispatch(setDashboards(updatedDashboards));
				});
				toast.success(`Summary items saved`);
			})
			.catch((err: AxiosError) => {
				toast.error(getErrorMessageOnPost('saving the summary items'));
				console.error(err.message);
			});
	};

	const handleEditModeToggle = () => {
		if (isEditMode) {
			// If we're turning edit mode off, save the changes
			saveSummaryItems();
		}
		setIsEditMode(!isEditMode);
	};

	return (
		<Styled.SummaryContainer>
			{metricBarItems && <MetricBar items={metricBarItems} />}
			<Styled.Wrapper className={classNames({ collapsed: false, inPublic: !props.inDataLibrary })}>
				<Styled.Header>
					<Styled.Title>Overview</Styled.Title>
					<Styled.Controls>
						{props.inDataLibrary && (
							<Styled.ToggleWrapper onClick={handleEditModeToggle}>
								<p className='blue'>{isEditMode ? 'Save' : 'Edit summary'}</p>
							</Styled.ToggleWrapper>
						)}
						{dashboard?.hasPaidMedia && (
							<Styled.ToggleWrapper>
								<p>Paid media {paidDataIncluded ? 'included' : 'excluded'}</p>
								<Styled.ToggleSwitch>
									<input
										type='checkbox'
										className={classNames({ checked: paidDataIncluded })}
										checked={paidDataIncluded}
										onChange={() => setPaidDataIncluded(!paidDataIncluded)}
									/>
									<Styled.Slider className={classNames({ checked: paidDataIncluded })} />
								</Styled.ToggleSwitch>
							</Styled.ToggleWrapper>
						)}
					</Styled.Controls>
				</Styled.Header>
				<Styled.Body>
					{typeof summaryData === 'undefined' || summaryData.length < 1 ? (
						<Styled.SpinnerWrapper>
							<LoadingSpinner />
						</Styled.SpinnerWrapper>
					) : (
						<Grid.Container>
							{summaryData.map((item: Entry<StatisticsSummary>, i: number) => {
								const title = item[0];
								const value = item[1];
								const hasValue =
									(Array.isArray(value) && value?.length > 0) ||
									(!Array.isArray(value) && Number(value) > 0) ||
									(typeof value === 'string' && value !== '0' && value?.length > 0);
								if (!hasValue || item[0] === 'viewThroughRate' || item[0] === 'estimatedUniqueReach') return null;
								if (!isEditMode && !enabledItems?.has(title as SummaryItemKey)) return null;

								return (
									<Grid.Column key={i} lg={3} md={4} sm={12}>
										<Styled.SummaryContainerItem
											style={{
												opacity: !enabledItems.has(title as SummaryItemKey) && isEditMode ? 0.5 : 1,
												position: 'relative',
												padding: isEditMode ? '8px' : '0px',
												border: isEditMode ? '1px solid ' + colors.slate : 'none',
											}}
										>
											{isEditMode && (
												<Styled.ItemToggle>
													<Styled.ToggleSwitch>
														<input
															type='checkbox'
															className={classNames({ checked: enabledItems.has(title as SummaryItemKey) })}
															checked={enabledItems.has(title as SummaryItemKey)}
															onChange={() => handleItemToggle(title as SummaryItemKey)}
														/>
														<Styled.Slider className={classNames({ checked: enabledItems.has(title as SummaryItemKey) })} />
													</Styled.ToggleSwitch>
												</Styled.ItemToggle>
											)}
											<Tooltip content={getTooltipContent(title)}>
												<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
													<Styled.SummaryContainerItemBox>
														{isLoading ? (
															<LoadingSpinner size='sm' />
														) : (
															<div data-testid='item-box-icon-container'>
																<Icon name={getIcon(item[0])} />
															</div>
														)}
													</Styled.SummaryContainerItemBox>
													<Styled.SummaryContainerItemData>
														<Heading as='h4'>{getTitle(title)}</Heading>
														<div
															onMouseEnter={() => {
																setSelectedValue(i);
																setDisplayRealValue(true);
															}}
															onMouseLeave={() => {
																setSelectedValue(null);
																setDisplayRealValue(false);
															}}
														>
															<Heading as='h4' data-testid={`${title}-value`} className='thin'>
																{displayValue(item, i)}
															</Heading>
														</div>
													</Styled.SummaryContainerItemData>
												</div>
											</Tooltip>
										</Styled.SummaryContainerItem>
									</Grid.Column>
								);
							})}
						</Grid.Container>
					)}
				</Styled.Body>
			</Styled.Wrapper>
		</Styled.SummaryContainer>
	);
};

export default Summary;
