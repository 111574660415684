import styled from 'styled-components';

import Modal from 'components/Modals/Modal';
import { InputFieldV2 } from 'styles/formElements/input';
import Grid from 'styles/grid/grid';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

const SummaryContainer = styled.div`
	margin-bottom: 40px;
`;

const SummaryToggle = styled.div`
	padding: 2rem 0rem 1rem 0rem;
	display: flex;
	justify-content: center;
`;

const SummarToggleButton = styled.span`
	position: relative;
	font-size: 1rem;
	text-align: center;
	width: 100%;
	margin: auto;
	display: flex;
	cursor: pointer;
	justify-content: center;
	span {
		display: flex;
		align-items: center;
		width: 95px;
		justify-content: space-between;
	}
	.icon {
		transition-duration: 200ms;
		transition-property: transform;
	}
	&.expand {
		.icon {
			transform: rotate(-180deg);
		}
	}
	&::before {
		content: '';
		display: block;
		width: calc((100% / 2) - 70px);
		height: 0.5px;
		background: ${colors.black};
		left: 0;
		top: 50%;
		position: absolute;
	}
	&::after {
		content: '';
		display: block;
		width: calc((100% / 2) - 70px);
		height: 0.5px;
		background: ${colors.black};
		right: 0;
		top: 50%;
		position: absolute;
	}
`;

const SummaryContainerRow = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	min-height: 155px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.4s ease-in-out;
	&.expand {
		max-height: 1000px;
	}
	&.inPreview {
		margin-bottom: 3rem;
		height: auto;
		max-height: unset;
	}
`;

const SummaryContainerItem = styled.div`
	position: relative;
	background: ${({ theme }) => theme.colors.white};
	border-radius: ${borderRadius.m};
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	padding: 0.5rem 0rem;
`;

const SummaryContainerItemBox = styled.div`
	position: relative;
	overflow: hidden;
	min-height: 56px;
	min-width: 56px;
	background-color: ${colors.mintBreezeDark};
	border: 1px solid ${colors.mintBreeze};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${borderRadius.m};
`;

const SummaryContainerItemData = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: default;
	h4 {
		margin-bottom: 0;
		&.thin {
			font-weight: 400;
		}
	}
`;

const Title = styled.div`
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.4;
	letter-spacing: 0em;
	text-align: left;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	margin-bottom: 0.688rem;
`;

const Body = styled.div`
	overflow: hidden;
	max-height: 1000px;
	transition: max-height 1s ease-in-out;
	p:last-child {
		margin-bottom: 0;
	}
`;

const Wrapper = styled.div<{ border?: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${colors.mist};
	border-radius: ${borderRadius.m};
	padding: 2rem;
	margin-top: 16px;
	&[aria-expanded='false'] {
		${Body} {
			max-height: 250px;
			transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
		}
	}
	&.inPublic {
		${Body} {
			max-height: auto;
		}
	}
`;

const SpinnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const ToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 8px;
	p {
		&.blue {
			color: ${colors.oceanBlue};
		}
		margin-bottom: 0;
	}
`;

const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	border-radius: 34px;

	&:before {
		position: absolute;
		content: '';
		height: 16px;
		width: 16px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		transition: 0.4s;
		border-radius: 50%;
	}

	&.checked {
		background-color: ${colors.emerald};
		&:before {
			transform: translateX(16px);
		}
	}
`;

const ToggleSwitch = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;
`;

const TextField = styled.input`
	${InputFieldV2};
	border: 1px solid #333;
	margin-bottom: 16px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	-moz-appearance: textfield;
`;

const CustomModal = styled(Modal)``;

const EditModalForm = styled.div`
	padding: 1rem 1.5rem;
`;

const ButtonGroup = styled.div`
	display: flex;
	width: 100%;
	gap: 8px;

	button:first-child {
		margin-left: auto;
	}
`;

const ErrorMessages = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
`;

const ErrorMessageItem = styled.li`
	color: ${colors.ruby};
	font-size: 0.875rem;
`;

const SummaryContainerItemWrapper = styled(Grid.Container)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 16px;
	flex-wrap: wrap;
	padding-top: 1rem;
`;

const Controls = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

const ItemToggle = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
`;

const Styled = {
	Controls,
	ItemToggle,
	SummaryContainerItemWrapper,
	SummaryContainer,
	SummaryContainerRow,
	SummaryContainerItem,
	SummaryContainerItemBox,
	SummaryContainerItemData,
	SummaryToggle,
	SummarToggleButton,
	Title,
	Wrapper,
	Header,
	Body,
	SpinnerWrapper,
	ToggleWrapper,
	ToggleSwitch,
	Slider,
	TextField,
	EditModalForm,
	ButtonGroup,
	CustomModal,
	ErrorMessages,
	ErrorMessageItem,
};

export default Styled;
